@font-face {
  font-family: "Oregon LDO";
  src: url("../../public//fonts//Oregon\ LDO.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Corporative Soft";
  src: url("../../public/fonts/CorporativeSoft-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MbCorpoSTitle";
  src: url("../../public/fonts/mbcorpostitle-regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
.home-text {
  color: #0082da;
  font-family: "Oregon LDO", sans-serif;
}
.home-text1 {
  color: #0082da;
  font-size: 24px;
  font-family: "Oregon LDO", sans-serif;
}
.home-text2 {
  color: #0082da;
  font-size: 18px;
  font-family: "MbCorpoSTitle", sans-serif;
}
.home-text3 {
  color: #0082da;
  font-family: "Oregon LDO", sans-serif;
}
.text-big {
  color: #ffffff;
  font-size: 60px;
  font-family: "Oregon LDO", sans-serif;
}
.text-small {
  color: #ffffff;
  font-size: 24px;
  font-family: "Oregon LDO", sans-serif;
}
.text {
  color: #ffffff;
  font-size: 36px;
  font-family: "Oregon LDO", sans-serif;
}
.text-1 {
  color: #ffffff;
  font-size: 20px;
  font-family: "MbCorpoSTitle", sans-serif;
}
.text-2 {
  color: #ffffff;
  font-size: 18px;
  font-family: "MbCorpoSTitle", sans-serif;
}
.text-3 {
  color: #323b4b;
  font-size: 20px;
  font-family: "MbCorpoSTitle", sans-serif;
}
.text-4 {
  color: #8a94a6;
  font-size: 16px;
  font-family: "MbCorpoSTitle", sans-serif;
}
.text-5 {
  color: #323b4b;
  font-size: 36px;
  font-family: "Oregon LDO", sans-serif;
}
.text-6 {
  color: #323b4b;
  font-size: 60px;
  font-family: "Oregon LDO", sans-serif;
}
.text-7 {
  color: #323b4b;
  font-size: 24px;
  font-family: "Oregon LDO", sans-serif;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #0082da;
}
.active {
  font-weight: bold;
  color: #0082da;
  font-family: "", sans-serif;
}

.NoActive {
  font-weight: normal;
  font-family: "", sans-serif;
}

.hover-bold:hover {
  font-weight: bold;
}

.home-text3 .char {
  display: inline-block;
  transition: opacity 1s, transform 1s;
  opacity: 0;
  transform: translateX(-20px);
}

.home-text3--fading.is-visible .char {
  opacity: 1;
  transform: translateX(0);
  transition-delay: var(--delay);
}

img {
  transition: transform 0.3s ease-in-out;
}

img:hover {
  transform: scale(1.1);
}
